form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

label {
  width: 100%;
  margin-bottom: 1rem;
  font-weight: bold;
  color: var(--main-color);
}

p.error {
  margin: 0;
  color: var(--main-color);
}

input {
  padding: 0.5rem;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  height: 2rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  box-sizing: border-box; /* Включить полное управление размером элемента через CSS */
}

/* Установить ширину поля ввода на 100%, исключая отступы, границы и заполнение */
input[type="text"],
input[type="email"],
input[type="number"] {
  width: calc(100% - 4px);
  /* Вычислить ширину, которая исключает отступы, границы и заполнение (padding) */
}

input[type="number"] {
  -moz-appearance: textfield; /* Для Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="checkbox"] {
  border-radius: 10px;
}

button[type="submit"] {
  background-color: rgb(135, 197, 116);
  color: #fdf9f5;
  padding: 1.3rem 4rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 3vh;
}

button[type="submit"]:hover {
  background-color: rgb(61, 155, 32);
}

.in_check {
  width: 100%;
  display: flex;
}
.in_check p {
  padding-left: 20px;
  color: var(--main-color);
  opacity: 0.8
}
.in_check input {
  width: 30px;
}
a {
  color: var(--main-color)
}
